<template>
  <AutoComplete
      v-model="internalModel"
      :suggestions="filteredProducts"
      @complete="searchProduct"
      @item-select="onItemSelect"
      @clear="onItemClear"
      :forceSelection="forceSelection"
      field="name"
      :minLength=3
      placeholder="Search product by code & name ..."
  >
    <template #item="{ item, index }">
      <div class="autocomplete-row">
        <div class="autocomplete-product-code" :title="item.product_code">
          {{ item.product_code }}
        </div>
        <div class="autocomplete-name" :title="item.name">{{ item.name }}</div>
      </div>
    </template>
  </AutoComplete>
</template>

<script>
export default {
  props: {
    value: {
      type: [Object, String],
      default: () => {
        return ""
      }
    },
    forceSelection: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    internalModel: {
      get() {
        return this.value;
      },
      set(newValue) {
        if (typeof newValue === 'string' && !this.isProductInList(newValue)) {
          const customValue = {product_id: null, name: newValue};
          this.$emit('input', customValue);
        } else {
          this.$emit('input', newValue);
        }
      }
    }
  },
  data() {
    return {
      filteredProducts: [],
    };
  },
  methods: {
    async searchProduct(event) {
      if (!event.query.trim()) return;

      try {
        const response = await this.ProductService.getProducts(event.query);
        this.filteredProducts = response;
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    isProductInList(name) {
      return this.filteredProducts.some(product => product.name === name);
    },
    onItemSelect(event) {
      this.$emit('productSelected', event);
    },
    onItemClear(event) {
      this.$emit('productCleared', event);
    },
  }
};
</script>

<style>
/* Style remains the same as it's mainly layout-related */
.autocomplete-row {
  display: flex;
  justify-content: space-between;
}

.autocomplete-name,
.autocomplete-product-code {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.autocomplete-product-code {
  width: 40%;
}

.autocomplete-name {
  width: 60%;
}

.p-autocomplete-panel {
  max-width: 100%;
  width: auto;
}

.p-autocomplete-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
